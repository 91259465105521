export const AI_CREATIVES_STRINGS = {
  PRIMART_CTA_TEXT: "Next",
  MODAL_TITLE: "Let's begin!",
  CONSUMERS_DEFAULT_TEXT: "Consumers, Students or Learners",
  DEFAULT_HASHTAG: "@defaulthandle",
  DEFAULT_BRAND_NAME: "Default Brand Name",
  DEFAULT_WEBSITE: "https://defaultwebsite.com",
  DEFAULT_HANDLE: "@defaulthandle",
  DEFAULT_COLORS: ["#296bd6", "#ff71ab"],
};

export const MENU_LABELS = {
  DOWNLOAD_LABEL: "Download",
  DELETE_LABEL: "Delete",
};

export const DESIGN_A_CREARIVE_BUTTON_TEXT = {
  DESIGN_WITH_AI: "Design with AI",
  DESIGN_CREATIVE: "Design a Creative",
};

export const creative_statuses = {
  uninitiated: -1,
  draft: 1,
  download: 2,
  ai_creatives: 3,
  handcrafted: 4,
};

export const creatives_ratios = {
  ONE_TO_ONE: "1:1",
  SIXTEEN_TO_NINE: "16:9",
  NINE_TO_SIXTEEN: "9:16",
  FIFTEEN_TO_TWO: "15:2",
  ONE_TO_FIVE: "1:5",
};

export const DEFAULT_DOWNLOAD_IMAGE_FILENAME = "postimage.jpeg";
